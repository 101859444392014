body {
  font-family: "Inter", sans-serif;
  color: #2D3748;
  font-size: 16px;
}

option {
  color: black;
}

.chakra-checkbox__input + .chakra-checkbox__control {
    background-color: white;
}

.chakra-checkbox__input + .chakra-checkbox__control[data-checked] {
  background: #FF6147 !important;
  border-color: #FF6147 !important;
  color: #FFFFFF !important;
}
